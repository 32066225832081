.address-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form {
    display: flex;
    flex-direction: column; 
    text-align: center;
  }
  
  .input {
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    width: 80%;
    min-width: 400px;
  }
  
  @media (max-width: 600px) {
    .input {
      width: 90%;
      min-width: unset;
    }
  }
  
  @media (max-width: 400px) {
    .input {
      width: 100%;
    }
  }
  
  .button {
    width: 50%;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #4323b4;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: bold;
    margin: 0 auto;
    position: relative;
  }

  .button:hover{
    cursor: pointer;
    
    transition: 0.2s ease-in-out;
    transform: translateY(-5px);
    width: 60%;
    background-color:#23b4a6 ;
    box-shadow: 0px 0px 10px #23fce7, 0px 0px 20px #23fce7, 0px 0px 30px #23fce7 ;
  }
  
  
