.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(24,86,99,1) 95%);
  }
  
  .header {
    text-align: center;
  }
  
  .title {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
    color: white;
    font-family: Poppins;
  }
  
  .address, .balance, .executable {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    color: white;
  }
  